import React from 'react'
import './mainvisual.css';
import mv1 from "../../images/mv/mv1.png";
import mv2 from "../../images/mv/mv2.png";
import { Link } from 'react-scroll';

const MainVisual = () => {
  return (
    <div className="mainvisual">
      <div className='_contents'>
        <div className="_textfield">
          <div className="_inner fadeIn">
            <h2>Transform Your Tennis Skills with <br />One-on-One <br />Training!</h2>
            <Link to="location" className='__cta ' smooth={true} duration={200}>NSW in Australia</Link>
          </div>
        </div>
        <div className="_imagefield">
          <div className="image_wrap _mv1"><img src={ mv1 }  alt="Veysel's Private Lesson" /></div>
          <div className="image_wrap _mv2"><img src={ mv2 }  alt="Veysel's Private Lesson" /></div>
        </div>
     
      </div>
       
    </div>
  )
}

export default MainVisual